import {getWrappedComponent} from '../../../appConfig';
import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {ExtendedGridGallery} from '../../../common/ExtendedGridGallery/ExtendedGridGallery';
import stylesParams from '../stylesParams';

export default getWrappedComponent({
  Component: ExtendedGridGallery,
  stylesParams,
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
